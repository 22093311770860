import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Contact
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>Looking for violin lessons?</h2>
          <p>
            Contact me today to schedule your child's virtual or in-person
            violin lesson. <br /> All first lessons are complimentary.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <a
              href="tel:+19145843358"
              className="icon solid fa-phone-alt major style1"
            >
              <span className="label">Phone</span>
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/melannie.rio/"
              target="_blank"
              rel="noreferrer"
              className="icon brands fa-instagram major style2"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="mailto:melanie.anne.riordan@gmail.com"
              className="icon solid fa-envelope major style3"
            >
              <span className="label">E-Mail</span>
            </a>
          </li>
        </ul>
        <Link to="/Scheduling" className="button primary">
          Schedule Now
        </Link>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            A dedicated educator who loves sharing her joy for music with
            students of all ages
          </h2>
          <p>
            Melanie uses a blend of the{' '}
            <a
              href="https://suzukiassociation.org/people/melanie-riordan/"
              target="_blank"
              rel="noreferrer"
            >
              Suzuki method
            </a>{' '}
            and traditional violin methods in her teaching to give every student
            a comprehensive violin education.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>A versatile musician</h2>
          <p>
            As an orchestral musician Melanie has had the privilege of
            performing in some of the worlds most distinguished concert halls
            such as New York’s Carnegie Hall and the Berliner Philharmonie.
            Playing at the Paris Philharmonie was one of her most memorable
            concerts.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>Bold & Creative</h2>
          <p>
            A fierce advocate for contemporary music, Melanie believes the way
            forward for classical music in the 21st century is to embrace the
            creation, promotion and appreciation of new works. She is now
            accepting requests for a conch shell improviser!
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>
            “Music gives a soul to the universe, wings to the mind, flight to
            the imagination and life to everything.” ― Plato
          </h2>
        </header>
        <ul className="features">
          <li className="icon solid fa-user">
            <Link to={`/About`}>
              <h3>About Me</h3>
              <p>
                Violinist Melanie Riordan is a unique performer and passionate
                educator currently based in South Florida.
              </p>
            </Link>
          </li>
          <li className="icon solid fa-graduation-cap">
            <Link to={`/Teaching`}>
              <h3>Teaching</h3>
              <p>
                Melanie is currently accepting new students for in-person
                lessons in South Florida and virtual lessons around the world.
              </p>
            </Link>
          </li>
          <li className="icon solid fa-headphones">
            <Link to={`/Listen`}>
              <h3>Listen</h3>
              <p>Listen to and watch past performances of Melanie.</p>
            </Link>
          </li>
          <li className="icon solid fa-camera-retro">
            <Link to={`/Gallery`}>
              <h3>Gallery</h3>
              <p>Get an inside look at Melanie's life as a musician.</p>
            </Link>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Are you ready to embark upon your musical journey?</h2>
        </header>
        <ul className="actions stacked">
          <li>
            <Link to="/Scheduling" className="button fit primary">
              Schedule Now
            </Link>
          </li>
          <li>
            <a href="/#" className="button fit">
              Back to Top
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
